export default {
	name: "orderDetails",
	components: {
		
	},
	data: () => {
		return {
            bannerSpanClass: "",
            bannerTitle: "",
            bannerTime: "",
            bannerText: "",
            btnLeftText: "",
            btnRightText: "",
            btnLeftColor: "greyBtn",
            editShow: false,
            orderStatusClass: "",
            orderStatusText: "",
            orderDetail: {},
            strCurXSoyego: localStorage.getItem("strCurXSoyego")
		}
	},
	watch: {
        '$route': 'fetchData'
	},
	created() {
        this.orderDetail = this.$route.query.data;
        this.orderDetail.order_goods.forEach(item => {
            item.sku_spec_format = JSON.parse(item.sku_spec_format);
        });
        // console.log(this.orderDetail)
	},
	mounted() {
        let i = this.$route.params.i;
        switch(i){
            case "0": {
                this.bannerSpanClass = "";
                this.bannerTitle = "Remaining Payment Time: ";
                this.bannerTime = "10 : 59 : 59";
                this.bannerText = "Please pay as soon as possible, After this time, your order will be cancelled";
                this.btnLeftText = "Cancel order";
                this.btnRightText = "CHECKOUT";
                this.editShow = true;
                this.orderStatusClass = "red";
                this.orderStatusText = "Waiting for Payment";
                break;
            };
            case "1": {
                this.bannerSpanClass = "yuan yuan-yellow";
                this.bannerTitle = "Processing";
                this.bannerText = "Merchant ready to ship";
                this.btnLeftText = "Support";
                this.orderStatusClass = "yellow";
                this.orderStatusText = "Processing";
                break;
            };
            case "2": {
                this.bannerSpanClass = "yuan yuan-green";
                this.bannerTitle = "Shipped";
                this.bannerText = "Items are rushing to your shipping address";
                this.btnLeftText = "Logistics";
                this.btnRightText = "Confirm Recei";
                this.btnLeftColor = "borderBtn";
                this.orderStatusClass = "green";
                this.orderStatusText = "Shipped";
                break;
            };
            case "3": {
                this.bannerSpanClass = "yuan yuan-yellow";
                this.bannerTitle = "Review";
                this.bannerText = "Please comment on this order item";
                this.btnLeftText = "Support";
                this.btnRightText = "Review";
                this.orderStatusClass = "yellow";
                this.orderStatusText = "Review";
                break;
            };
            case "4": {
                this.bannerSpanClass = "yuan yuan-grey";
                this.bannerTitle = "Completed";
                this.bannerText = "Order completed";
                this.btnLeftText = "Delete Order";
                this.btnRightText = "Repurchase";
                this.orderStatusClass = "grey";
                this.orderStatusText = "Completed";
                break;
            };
            case "5": {
                this.bannerSpanClass = "yuan yuan-back";
                this.bannerTitle = "Canceled";
                this.bannerText = "Order canceled";
                this.btnLeftText = "Delete Order";
                this.btnRightText = "Repurchase";
                this.orderStatusClass = "back";
                this.orderStatusText = "Canceled";
                break;
            };
            default: break;
        }
	},
	computed: {

	},
	methods: {
        backClick() {
            this.$router.go(-1)
        },
        btnLeftClick() {
            let i = this.$route.params.i;
            if(i == "2"){
                this.$router.push({ path: "/personal/logistics" })
            }
        },
		fetchData() {
			let i = this.$route.params.i;
            switch(i){
                case "0": {
                    this.bannerSpanClass = "";
                    this.bannerTitle = "Remaining Payment Time: ";
                    this.bannerTime = "10 : 59 : 59";
                    this.bannerText = "Please pay as soon as possible, After this time, your order will be cancelled";
                    this.btnLeftText = "Cancel order";
                    this.btnRightText = "CHECKOUT";
                    this.editShow = true;
                    this.orderStatusClass = "red";
                    this.orderStatusText = "Waiting for Payment";
                    break;
                };
                case "1": {
                    this.bannerSpanClass = "yuan yuan-yellow";
                    this.bannerTitle = "Processing";
                    this.bannerText = "Merchant ready to ship";
                    this.btnLeftText = "Support";
                    this.orderStatusClass = "yellow";
                    this.orderStatusText = "Processing";
                    break;
                };
                case "2": {
                    this.bannerSpanClass = "yuan yuan-green";
                    this.bannerTitle = "Shipped";
                    this.bannerText = "Items are rushing to your shipping address";
                    this.btnLeftText = "Logistics";
                    this.btnRightText = "Confirm Recei";
                    this.btnLeftColor = "borderBtn";
                    this.orderStatusClass = "green";
                    this.orderStatusText = "Shipped";
                    break;
                };
                case "3": {
                    this.bannerSpanClass = "yuan yuan-yellow";
                    this.bannerTitle = "Review";
                    this.bannerText = "Please comment on this order item";
                    this.btnLeftText = "Support";
                    this.btnRightText = "Review";
                    this.orderStatusClass = "yellow";
                    this.orderStatusText = "Review";
                    break;
                };
                case "4": {
                    this.bannerSpanClass = "yuan yuan-grey";
                    this.bannerTitle = "Completed";
                    this.bannerText = "Order completed";
                    this.btnLeftText = "Delete Order";
                    this.btnRightText = "Repurchase";
                    this.orderStatusClass = "grey";
                    this.orderStatusText = "Completed";
                    break;
                };
                case "5": {
                    this.bannerSpanClass = "yuan yuan-back";
                    this.bannerTitle = "Canceled";
                    this.bannerText = "Order canceled";
                    this.btnLeftText = "Delete Order";
                    this.btnRightText = "Repurchase";
                    this.orderStatusClass = "back";
                    this.orderStatusText = "Canceled";
                    break;
                };
                default: break;
            }
		}
	}
}
