<template>
    <div class="orderDetails">
        <div class="orderDetailsMain">
            <div class="orderDetails-title">
                <div class="back" @click="backClick">
                    <img src="@/assets/images/personal/z.png" />
                    <span>Back</span>
                </div>
                <h5>Orders Details</h5>
            </div>
            <div class="orderDetails-main">
                <div class="main-banner">
                    <div class="banner-time">
                        <span :class="bannerSpanClass"
                            >{{ bannerTitle }}
                            <p>{{ bannerTime }}</p></span
                        >
                    </div>
                    <p>{{ bannerText }}</p>
                    <div class="btn">
                        <button v-show="btnLeftText != ''" :class="btnLeftColor" @click="btnLeftClick()">{{ btnLeftText }}</button>
                        <button v-show="btnRightText != ''" class="backBoldBtn">{{ btnRightText }}</button>
                    </div>
                </div>
                <div class="main-news">
                    <div>
                        <span>Order NO.</span>
                        <p>{{orderDetail.order_no}}</p>
                    </div>
                    <div>
                        <span>Date</span>
                        <p>{{orderDetail.create_time}}</p>
                    </div>
                    <div>
                        <span>Quantity</span>
                        <p>{{orderDetail.goods_num}} Item</p>
                    </div>
                </div>
                <div class="main-address">
                    <!-- <div class="address">
                        <h5>Shipping Address</h5>
                        <div>
                            <div>
                                <span>Kyrie</span>
                                <p>+1 863-7897</p>
                            </div>
                            <div v-show="editShow">
                                <img src="@/assets/images/personal/edit1.png" />
                            </div>
                        </div>
                        <p>1600 Amphitheatre Parkway, Mountain View, California, U.S.</p>
                    </div> -->
                    <div class="address">
                        <h5>Billing Address</h5>
                        <div>
                            <div>
                                <span>{{orderDetail.name}}</span>
                                <p>{{orderDetail.mobile}}</p>
                            </div>
                            <div v-show="editShow">
                                <img src="@/assets/images/personal/edit1.png" />
                            </div>
                        </div>
                        <p>{{orderDetail.full_address}}</p>
                    </div>
                </div>
                <div class="main-order">
                    <div class="order-header">
                        <div class="flexProduts">
                            <span>Produts</span>
                        </div>
                        <div class="flexAmount">
                            <span>Amount</span>
                        </div>
                        <div class="flexSku">
                            <span>SKU</span>
                        </div>
                        <div class="flexStatus">
                            <span>Status</span>
                        </div>
                    </div>
                    <div class="order-body">
                        <div class="flexProduts">
                            <div v-for="(item, index) in orderDetail.order_goods" :key="index">
                                <div class="img"><img :src="item.sku_image" /></div>
                                <div class="textMain">
                                    <div class="text">
                                        <span>{{item.goods_name}}</span>
                                        <div v-if="item.sku_spec_format" style="display: flex;">
                                            <p style="margin-right: 10px" v-if="item.sku_spec_format[0]">Color: {{item.sku_spec_format[0].spec_value_name}} </p>
                                            <p v-if="item.sku_spec_format[1]">Size: {{item.sku_spec_format[1].spec_value_name}}</p>
                                        </div>
                                    </div>
                                    <p class="number">x{{item.num}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="flexAmount">
                            <div v-for="(item, index) in orderDetail.order_goods" :key="index">
                                <span>{{strCurXSoyego}}{{item.goods_money}}</span>
                            </div>
                        </div>
                        <div class="flexSku">
                            <div v-for="(item, index) in orderDetail.order_goods" :key="index">
                                <span>{{item.sku_id}}</span>
                            </div>
                        </div>
                        <div class="flexStatus">
                            <span :class="orderStatusClass">{{ orderStatusText }}</span>
                        </div>
                    </div>
                </div>
                <div class="main-total">
                    <div class="left"></div>
                    <div class="right">
                        <div class="price">
                            <div>
                                <span>Retail Price</span>
                                <p>{{strCurXSoyego}}{{orderDetail.goods_money}}</p>
                            </div>
                            <div>
                                <span>Shipping Freight</span>
                                <p>{{strCurXSoyego}}{{orderDetail.delivery_money}}</p>
                            </div>
                        </div>
                        <div class="total">
                            <span>Total</span>
                            <p>{{strCurXSoyego}}{{orderDetail.order_money}}</p>
                        </div>
                    </div>
                </div>
                <div class="main-methods">
                    <div>
                        <h5>Logistics Methods</h5>
                        <span>{{orderDetail.delivery_type}} Shipping</span>
                        <!-- <p>Estimated delivery time: Expected Friday, Jan 17 - Monday, Jan 19.</p> -->
                    </div>
                    <div>
                        <h5>Payment</h5>
                        <span>MasterCard Credit Card</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.about-content {
    background: #f7f7f7;
    padding-top: 0;
}
</style>
<style lang="scss" scoped>
@import "./css/orderDetails.scss";
</style>

<script>
import orderDetails from "./js/orderDetails.js"

export default {
    name: "orderDetails",
    components: {},
    mixins: [orderDetails]
}
</script>